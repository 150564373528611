var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.changed
        ? _c(
            "vs-button",
            {
              staticClass: "btn-view-changes",
              attrs: { color: "warning", type: "border" },
              on: {
                click: function($event) {
                  _vm.showModalAlteracoes = true
                }
              }
            },
            [
              _c("i", {
                staticClass: "onpoint-clock-counter-clockwise icon-font pr-3"
              }),
              _vm._v(" Ver alterações\n  ")
            ]
          )
        : _vm._e(),
      _c(
        "vs-popup",
        {
          staticClass: "alteracao-modal",
          attrs: {
            title: "Alterações da proposta",
            active: _vm.showModalAlteracoes
          },
          on: {
            "update:active": function($event) {
              _vm.showModalAlteracoes = $event
            }
          }
        },
        [
          _vm._l(_vm.alteracoes, function(item, index) {
            return _c(
              "div",
              { key: index },
              [
                _c(
                  "vs-row",
                  [
                    _c("vs-col", { attrs: { "vs-lg": "12", "vs-sm": "12" } }, [
                      _c("p", { staticClass: "w-full" }, [
                        _c("span", { staticClass: "font-semibold" }, [
                          _vm._v("Data da alteração: ")
                        ]),
                        _vm._v(
                          _vm._s(
                            _vm.$utils.dateToDDMMYYYYHHmm(item.createDate)
                          ) + "\n          "
                        )
                      ]),
                      _c("p", { staticClass: "w-full" }, [
                        _c("span", { staticClass: "font-semibold" }, [
                          _vm._v("Alterado por: ")
                        ]),
                        _vm._v(_vm._s(item.author) + "\n          ")
                      ])
                    ])
                  ],
                  1
                ),
                _c(
                  "vs-row",
                  [
                    _c("vs-col", { attrs: { "vs-lg": "12", "vs-sm": "12" } }, [
                      _c(
                        "div",
                        { staticClass: "container-changed w-full" },
                        _vm._l(item.diffProposalRisk, function(
                          itemDiff,
                          index
                        ) {
                          return _c("span", { key: index }, [
                            _c("p", { staticClass: "w-full font-semibold" }, [
                              _vm._v(
                                "\n                " +
                                  _vm._s(itemDiff.label) +
                                  "\n              "
                              )
                            ]),
                            _c("p", {
                              staticClass:
                                "w-full font-semibold text-primary pb-5",
                              domProps: { innerHTML: _vm._s(itemDiff.value) }
                            })
                          ])
                        }),
                        0
                      )
                    ])
                  ],
                  1
                )
              ],
              1
            )
          }),
          _c(
            "vs-row",
            [
              _vm.showDesfazer
                ? _c(
                    "vs-col",
                    {
                      attrs: {
                        "vs-w": "12",
                        "vs-type": "flex",
                        "vs-justify": "center"
                      }
                    },
                    [
                      _c(
                        "vs-button",
                        {
                          staticClass: "btn",
                          attrs: { type: "border" },
                          on: {
                            click: function($event) {
                              _vm.showModalConfirm = true
                            }
                          }
                        },
                        [
                          _c("i", {
                            staticClass:
                              "onpoint-arrow-counter-clockwise icon-font pr-3"
                          }),
                          _vm._v("\n          Reverter alterações\n        ")
                        ]
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          ),
          _c(
            "vs-popup",
            {
              staticClass: "popup-confirm",
              attrs: {
                title: "Deseja realmente desfazer às alterações?",
                active: _vm.showModalConfirm
              },
              on: {
                "update:active": function($event) {
                  _vm.showModalConfirm = $event
                }
              }
            },
            [
              _c("div", { staticClass: "vw-row mt-3" }, [
                _c(
                  "div",
                  { staticClass: "vx-col w-full text-right" },
                  [
                    _c(
                      "vs-button",
                      {
                        staticClass: "button-default mr-5",
                        on: {
                          click: function($event) {
                            _vm.showModalConfirm = false
                          }
                        }
                      },
                      [_vm._v("\n            Cancelar\n          ")]
                    ),
                    _c(
                      "vs-button",
                      {
                        staticClass: "button-primary",
                        on: {
                          click: function($event) {
                            return _vm.desfazerAlteracao()
                          }
                        }
                      },
                      [_vm._v("\n            Confirmar\n          ")]
                    )
                  ],
                  1
                )
              ])
            ]
          )
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }